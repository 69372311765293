import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Chip,
    CircularProgress,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { ActionButton } from "../../components/UI";
import SetProjectLabelsController from "./controllers/SetProjectLabelsController";
import ApplicationSettingsMenu from "./ApplicationSettingsMenu";
import HelperTooltip from "../../components/HelperTooltip";
import { AppContext } from "../../context/AppContext";
import telemetry from "../../services/telemetry.ts";
import StyledJobSummary from "../../styles/features/applications/JobSummary.Styled";
import InputWithCopy from "../settings/components/InputWithCopy";
import voila from "../../assets/frameworks/v2/voila.png";
import streamlit from "../../assets/frameworks/v2/streamlit.png";
import shinyR from "../../assets/frameworks/v2/shiny-r.png";
import docker from "../../assets/frameworks/v2/docker.png";
import panel from "../../assets/frameworks/v2/panel.png";
import solara from "../../assets/frameworks/v2/solara.png";
import dash from "../../assets/frameworks/v2/dash.png";
import flask from "../../assets/frameworks/v2/flask.png";
import chainlit from "../../assets/frameworks/v2/chainlit.png";
import { AccountContext } from "../user/Account";
import {
    checkIfEKSDeployment,
    dispatchComponentReadyEvent,
} from "../../utils/utils.ts";
import ApplicationLinksController from "./ApplicationLinksController";
import { ApplicationContext } from "./ApplicationProvider";
import { RestrictedController } from "./hoc/userValidation";
import StyledChip from "../../styles/components/Chip.Styled";
import {
    getEKSJobStatusChipColor,
    getEKSJobStatusMapping,
    isTransitioningJobStatus,
} from "../../utils/uiUtils.ts";

const options = [
    { id: "voila", label: "Voila", image: voila },
    { id: "docker", label: "Docker", image: docker },
    { id: "panel", label: "Panel", image: panel },
    { id: "solara", label: "Solara", image: solara },
    { id: "streamlit", label: "Streamlit", image: streamlit },
    { id: "shiny-r", label: "Shiny (R)", image: shinyR },
    { id: "dash", label: "Dash", image: dash },
    { id: "flask", label: "Flask", image: flask },
    { id: "chainlit", label: "Chainlit", image: chainlit },
];

function JobSummary({
    status,
    job,
    jobInfo,
    isUrlUp,
    isApplicationProtected,
    isAnalyticsProtected,
}) {
    const toRender = status && job && jobInfo;
    const disableLabelsEdit = true;
    const jobLabels = job.labels;
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [searchParams] = useSearchParams();
    const FEATURE_ID_ANALYTICS = "viewAnalyticsReport";

    function validateAnalyticsAccess() {
        if (searchParams.get("referredProjectId")) {
            return false;
        }
        return canUserAccessComponent(FEATURE_ID_ANALYTICS);
    }

    const frameworkImage = options.find((o) => o.id === jobInfo?.type)?.image;

    useEffect(() => {
        dispatchComponentReadyEvent();
    }, []);

    const isBigScreen = useMediaQuery("(min-width: 1024px)");

    const labelsController = (
        <SetProjectLabelsController
            labels={jobLabels}
            hideLabel
            disabled={disableLabelsEdit}
        />
    );

    return (
        toRender && (
            <StyledJobSummary className="Top" data-testid="job-status-summary">
                <Box className="JobInfoContainer">
                    <Box className="FrameworkLabelContainer">
                        <Box className="Framework">
                            <img
                                className="FrameworkImage"
                                data-testid="framework-image"
                                src={frameworkImage}
                                alt={jobInfo.type}
                            />

                            {checkIfEKSDeployment(jobInfo) && (
                                <StyledChip
                                    label={getEKSJobStatusMapping(job.status)}
                                    color={getEKSJobStatusChipColor(job.status)}
                                    size="small"
                                    icon={
                                        isTransitioningJobStatus(job.status) ? (
                                            <CircularProgress />
                                        ) : null
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        className="HardWareSettings"
                        data-testid="hardware-settings"
                    >
                        <p>
                            {jobInfo.cpu} CPU - {jobInfo.ram} RAM -{" "}
                            {!jobInfo.gpu || Number(jobInfo.gpu) === 0
                                ? "No"
                                : `${jobInfo.gpu}`}{" "}
                            GPU -
                            {!jobInfo.storage || Number(jobInfo.storage) === 0
                                ? " No"
                                : ` ${jobInfo.storage} GB `}{" "}
                            {isBigScreen && jobLabels && jobLabels.length > 0
                                ? "Storage - "
                                : "Storage"}
                            {isBigScreen ? (
                                <span style={{ display: "inline-flex" }}>
                                    {labelsController}
                                </span>
                            ) : (
                                <div>{labelsController}</div>
                            )}
                        </p>
                    </Box>
                </Box>

                <Box className="ActionsContainer">
                    <ApplicationSettingsMenu
                        projectId={jobInfo.project_id}
                        jobId={jobInfo.id}
                        jobStatus={status}
                        applicationUrl={job?.resources?.webservice}
                        projectName={job.projectName}
                        canRedeploy={job.can_redeploy}
                        isEKSDeployment={checkIfEKSDeployment(jobInfo)}
                    />

                    <RestrictedController
                        validationFunction={() => validateAnalyticsAccess()}
                        featureId={FEATURE_ID_ANALYTICS}
                    >
                        <ActionButton
                            variant="outlined"
                            data-testid="application-analytics-button"
                            disabled={!isUrlUp}
                            onClick={() =>
                                window.open(
                                    `${job?.resources?.webservice}/analytics-report`,
                                    "_blank"
                                )
                            }
                            startIcon={
                                isAnalyticsProtected ? <LockIcon /> : undefined
                            }
                        >
                            Analytics Report
                        </ActionButton>
                    </RestrictedController>
                    <HelperTooltip
                        text={
                            isApplicationProtected ? (
                                <div
                                    role="button"
                                    onKeyDown={(e) => {}}
                                    tabIndex="0"
                                    data-testid="protected-application-tooltip"
                                >
                                    <div>
                                        This application is protected with
                                        password.
                                    </div>
                                </div>
                            ) : (
                                "Click here to open the application"
                            )
                        }
                    >
                        <ActionButton
                            testid="view-application-button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const url = job?.resources?.webservice;
                                telemetry.log(
                                    `${telemetry.Pages.ApplicationStatus}-OpenApplicationClick`,
                                    {
                                        metadata: {
                                            job: JSON.stringify(job),
                                            projectId: jobInfo.project_id,
                                        },
                                    }
                                );

                                window.open(`${url}`);
                            }}
                            disabled={!isUrlUp}
                            startIcon={
                                isApplicationProtected ? (
                                    <LockIcon />
                                ) : undefined
                            }
                        >
                            View application
                        </ActionButton>
                    </HelperTooltip>

                    <ApplicationLinksController
                        defaultUrl={job?.resources?.webservice}
                        isEKSDeployment={checkIfEKSDeployment(jobInfo)}
                    />
                </Box>
            </StyledJobSummary>
        )
    );
}

JobSummary.propTypes = {
    status: PropTypes.string.isRequired,
    job: PropTypes.shape({
        projectName: PropTypes.string,
        summary: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        status: PropTypes.string.isRequired,
        resources: PropTypes.shape({
            webservice: PropTypes.string,
            is_url_up: PropTypes.bool,
        }),
        authentication: PropTypes.shape({
            username: PropTypes.string,
            password: PropTypes.string,
        }),
        labels: PropTypes.arrayOf(PropTypes.string),
        can_redeploy: PropTypes.bool,
    }).isRequired,
    jobInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        project_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        auth_enabled: PropTypes.bool,
        cpu: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        ram: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        gpu: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        storage: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        appLinks: PropTypes.arrayOf(
            PropTypes.shape({
                isDefault: PropTypes.bool,
                isActive: PropTypes.bool,
                id: PropTypes.string,
                projectId: PropTypes.string,
            })
        ).isRequired,
    }).isRequired,
    isUrlUp: PropTypes.bool.isRequired,
    isApplicationProtected: PropTypes.bool.isRequired,
    isAnalyticsProtected: PropTypes.bool.isRequired,
};

export default JobSummary;
