import JSZip from "jszip";
import { Framework, UserType } from "../models/enum.ts";
import {
    ErrorMessage,
    JobInfoResponse,
    Project,
} from "../models/interfaces.ts";
import ploomberAPI from "../services/ploomberAPI.ts";
import telemetry from "../services/telemetry.ts";
import communityUserConfiguration from "../features/user/configurations/community.json";
import proUserConfiguration from "../features/user/configurations/pro.json";
import teamsUserConfiguration from "../features/user/configurations/teams.json";
import adminUserConfiguration from "../features/user/configurations/admin.json";

export const DEFAULT_USER_TYPE = UserType.COMMUNITY.value;

export const parseErrorMessage = (error: ErrorMessage): string =>
    `${error?.title ?? "Internal error"} - ${
        error?.detail ??
        "Please contact us on slack for support, link on the bottom"
    }`;

export const capitalize = (text: string): string =>
    text.length > 0 ? `${text[0].toUpperCase()}${text.slice(1)}` : text;

export const setAccessToken = (token) => {
    localStorage.setItem("ploomber_token", token);
};
export const getAccessToken = () => localStorage.getItem("ploomber_token");

export const isBareURL = (input: string): boolean => {
    // Regular expression to match a URL without protocol, www, subdomains, or query params
    const urlPattern = /^[^/:]+\.[^/:]+$/;

    return urlPattern.test(input);
};

export function formatDate(inputDate: string): string {
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const utcFormat = new Date(`${inputDate}Z`);
    const formattedDate: string = new Date(utcFormat).toLocaleDateString(
        "en-US",
        options
    );
    return formattedDate;
}

export function getBillingDue(inputDate: string): string {
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const date = new Date(inputDate);
    const localDate = new Date(
        date.toLocaleString("en-US", { timeZone: "UTC" })
    );
    localDate.setDate(localDate.getDate() + 1);
    const nextFormattedDate = localDate.toLocaleDateString("en-US", options);
    return nextFormattedDate;
}

export class CustomError extends Error {
    title: string;

    detail: string;

    constructor(
        title: string,
        detail: string,
        message: string = "CustomError"
    ) {
        // Pass the error message to the base class (Error)
        super(message);

        // Set the prototype explicitly to ensure correct instanceof checks
        Object.setPrototypeOf(this, CustomError.prototype);

        // Set additional properties
        this.title = title;
        this.detail = detail;

        // Capture the stack trace
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError);
        }
    }
}

export const extractLatestTimestamp = (logString) => {
    const logEntries = logString.split("\n");
    const timestamps = logEntries.map((entry) => {
        const match = entry.match(
            /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}000/
        );
        return match ? new Date(match[0]).getTime() : 0;
    });
    return Math.max(...timestamps);
};

export const dispatchComponentReadyEvent = () => {
    const event = new CustomEvent("onComponentReady");
    document.dispatchEvent(event);
};

export const isSubdomain = (domain: string, domainList: Array<string>) => {
    const domainSplit = domain.split(".");

    if (domainSplit.length < 3) {
        return false;
    }
    const domainSuffixToCheck = domain.slice(domain.indexOf(".") + 1);

    for (let i = 0; i < domainList.length; i++) {
        if (domainSuffixToCheck === domainList[i]) {
            return true;
        }
    }
    return false;
};

export const getSubdomains = (
    domain: string,
    domainList: Array<string>
): Array<string> => {
    const subdomains = domainList.filter(
        (d) => d.endsWith(domain) && d !== domain
    );
    return subdomains;
};

export const isMultiLevelSubdomain = (
    domain: string,
    domainList: Array<string>
): boolean => {
    for (let i = 0; i < domainList.length; i++) {
        if (domain.endsWith(domainList[i])) {
            const urlPrefix = domain.slice(0, domain.indexOf(domainList[i]));
            const parts = urlPrefix.split(".");
            if (parts.length > 2) {
                return true;
            }
            return false;
        }
    }
    return false;
};

export const shouldValidateAction = (currentPlan: string, newPlan: string) => {
    let shouldValidate = false;

    const plans = Object.values(UserType)
        .filter((userType) => userType.value !== "admin")
        .map((userType) => userType.value);

    const newIndex = plans.indexOf(newPlan);
    const currentIndex = plans.indexOf(currentPlan);

    if (newIndex === -1 || currentIndex === -1) {
        shouldValidate = false;
    } else {
        shouldValidate = newIndex < currentIndex;
    }

    return shouldValidate;
};

export function getUserConfig(userType, feature) {
    let userConfiguration;
    switch (userType || DEFAULT_USER_TYPE) {
        case UserType.COMMUNITY.value:
            userConfiguration = communityUserConfiguration;
            break;
        case UserType.PRO.value:
            userConfiguration = proUserConfiguration;
            break;
        case UserType.TEAMS.value:
            userConfiguration = teamsUserConfiguration;
            break;
        case UserType.ADMIN.value:
            userConfiguration = adminUserConfiguration;
            break;
        default:
            break;
    }
    return userConfiguration[feature];
}

export function canUserAccessFeature(userType, componentId) {
    const userAllowedFeatures = getUserConfig(userType, "allowedFeatures");
    return userAllowedFeatures.includes(componentId);
}

export const cancelSubscription = async (
    updateSnackbarStatus,
    reason,
    callback
) => {
    try {
        const sub = await ploomberAPI.cancelSubscription(reason);
        updateSnackbarStatus({
            message: "Successfully canceled Ploomber Cloud subscription",
        });

        if (callback && typeof callback === "function") {
            callback(sub);
        }
    } catch {
        updateSnackbarStatus({
            message: "Failed to Update Cancelation Billing Period",
            severity: "error",
        });
    } finally {
        telemetry.log("Cancel Subscription");
    }
};

export const getFirstExampleByFramework = (
    framework: string,
    examples: object
) => {
    if (examples && framework in examples && examples[framework].length > 0) {
        return examples[framework][0];
    }
    return null;
};

export const getAPIVersion = (isEKSDeployment: boolean) =>
    isEKSDeployment ? "v2" : "";

export const checkIfEKSDeployment = (jobInfo: JobInfoResponse): boolean =>
    jobInfo?.is_eks;

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    // https://stackoverflow.com/a/16245768
    try {
        const base64Marker = "base64,";
        const base64Index = b64Data.indexOf(base64Marker) + base64Marker.length;
        const base64String = b64Data.substring(base64Index);
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    } catch (err) {
        console.error("Error getting the avatar picture contents:", err);
        return null;
    }
};

export function getRedirectParam() {
    const searchParams = new URLSearchParams(window.location.search);
    const redirect = searchParams.get("redirect");
    const options = redirect ? `redirect=${encodeURIComponent(redirect)}` : "";

    return options;
}

export const hasApps = (resourcesInfo, curJobResourceInfo) => {
    if (resourcesInfo) {
        let cpuUsage = Number(resourcesInfo.total_cpu);
        let ramUsage = Number(resourcesInfo.total_ram);
        let gpuUsage = resourcesInfo.total_gpu;

        if (curJobResourceInfo) {
            cpuUsage -= Number(curJobResourceInfo.currentCpu);
            ramUsage -= Number(curJobResourceInfo.currentRam);
            gpuUsage -= Number(curJobResourceInfo.currentGpu);
        }

        return cpuUsage > 0 || ramUsage > 0 || gpuUsage > 0;
    }
    return undefined;
};

export const checkFileSize = (userType, zipFile) => {
    const fileSize = zipFile.size;
    const maxAppSizeMB = getUserConfig(userType, "maxAppSizeMB");
    const exceeded = fileSize > maxAppSizeMB * 1024 * 1024;
    return { fileSize, maxAppSizeMB, exceeded };
};

const VALID_PYTHON_PROJECT_TYPES = new Set([
    Framework.STREAMLIT,
    Framework.PANEL,
    Framework.SOLARA,
    Framework.DASH,
    Framework.FLASK,
    Framework.CHAINLIT,
]);

const VALID_DOCKER_PROJECT_TYPES = new Set(["fastapi", "gradio", "shiny"]);

const checkImportsForProjectType = async (fileContent, types) => {
    const patterns = types
        .map((proj) => [
            new RegExp(`\\bimport\\s+${proj}\\b`),
            new RegExp(`\\bfrom\\s+${proj}\\b\\s+import`),
            new RegExp(`\\bimport\\s+${proj}\\s+as\\s+\\w+\\b`),
            new RegExp(`\\bfrom\\s+${proj}\\.\\w+\\s+import`),
        ])
        .flat();

    return patterns.reduce((result, pattern) => {
        if (result) return result;
        if (pattern.test(fileContent)) {
            return types.find((type) => pattern.source.includes(type));
        }
        return null;
    }, null);
};

export const identifyFramework = async (file) => {
    try {
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file);

        // Find root directory
        const files = Object.keys(zipContent.files);
        let rootDir = "";
        if (files.length > 0) {
            // Get the first path component of the first file
            const firstPath = files[0].split("/")[0];

            // Check if all files start with this path and it appears as a directory
            const isCommonRoot = files.every((f) =>
                f.startsWith(`${firstPath}/`)
            );
            const rootExists = zipContent.files[`${firstPath}/`]?.dir === true;

            if (isCommonRoot && rootExists) {
                rootDir = `${firstPath}/`;
            }
        }

        // Check for app.py
        const appPyPath = rootDir ? `${rootDir}app.py` : "app.py";
        const appPyFile = zipContent.files[appPyPath];

        let nativeType = null;
        if (appPyFile) {
            const content = await appPyFile.async("text");

            nativeType = await checkImportsForProjectType(
                content,
                Array.from(VALID_PYTHON_PROJECT_TYPES)
            );

            const dockerType = await checkImportsForProjectType(
                content,
                Array.from(VALID_DOCKER_PROJECT_TYPES)
            );

            if (dockerType) return Framework.DOCKER;
        }

        // Check for Dockerfile
        const dockerfilePath = rootDir ? `${rootDir}Dockerfile` : "Dockerfile";
        if (zipContent.files[dockerfilePath]) return Framework.DOCKER;

        // Check for R Shiny
        const appRPath = rootDir ? `${rootDir}app.R` : "app.R";
        if (zipContent.files[appRPath]) return Framework.SHINY_R;

        // Check for Jupyter Notebook
        const appIpynbPath = rootDir ? `${rootDir}app.ipynb` : "app.ipynb";
        if (zipContent.files[appIpynbPath]) return Framework.VOILA;

        return nativeType || null;
    } catch (error) {
        console.error("Error identifying framework:", error);
        return null;
    }
};
