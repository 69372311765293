import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ApplicationStatus as EKSApplicationStatus } from "./eks";

import ploomberAPI from "../../services/ploomberAPI.ts";
import { ECSApplicationStatus } from "./index";
import { AppContext } from "../../context/AppContext";

// This component renders the appropriate application status page.
// After the full migration, it can be removed, and EKSApplicationStatus can be rendered directly from App.js.

function ApplicationStatusRenderer() {
    const [renderComponent, setRenderComponent] = useState(undefined);
    const [jobInfo, setJobInfo] = useState(undefined);
    const { navigate, updateSnackbarStatus } = useContext(AppContext);

    const params = useParams();
    const projectId = params?.projectId || undefined;
    const jobId = params?.jobId || undefined;

    useEffect(() => {
        const checkWithJob = async () => {
            try {
                const jobInfoResponse = await ploomberAPI.getJobInfo(jobId);
                setRenderComponent(jobInfoResponse.is_eks);
                setJobInfo(jobInfoResponse);
            } catch (err) {
                updateSnackbarStatus({
                    message: "Application not found",
                    severity: "error",
                });

                navigate(`/applications`);
            }
        };

        checkWithJob();
    }, []);

    if (renderComponent !== undefined) {
        if (renderComponent) {
            return <EKSApplicationStatus jobInfo={jobInfo} />;
        }
        return <ECSApplicationStatus />;
    }

    return "";
}

export default ApplicationStatusRenderer;
