import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as uuid from "uuid";
import Stepper from "../../components/Stepper";
import { capitalize } from "../../utils/utils.ts";
import { JobStatus } from "../../models/enum.ts";
import { ApplicationContext } from "./ApplicationProvider";

function JobStepper({ job, id }) {
    const [JobStepsFinalStatus, setJobStepsFinalStatus] = useState({
        deploy: JobStatus.FINISHED,
        redeploy: JobStatus.FINISHED,
        "build-docker": JobStatus.FINISHED,
        webservice: JobStatus.ACTIVE,
        "serving-traffic": JobStatus.ACTIVE,
    });

    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState();
    const setScroller = () => {
        // https://dev.to/bhupendra1011/how-to-know-when-css-position-sticky-get-s-applied-4gk2
        // to check when element get's position sticky
        const observer = new IntersectionObserver((entries) => {
            const stickyContainer = document.querySelector(".sticky-container");
            if (stickyContainer) {
                // no intersection
                if (entries[0]?.intersectionRatio === 0) {
                    stickyContainer.classList?.add("sticky-container-sticky");
                }
                // fully intersects
                else if (entries[0]?.intersectionRatio === 1) {
                    stickyContainer.classList?.remove(
                        "sticky-container-sticky"
                    );
                }
            }
        });

        observer.observe(document.querySelector(".sticky-container-top"));
    };

    function capitalizeStepLabel(label) {
        const parsedLabel = label.replaceAll("-", " ");
        return capitalize(parsedLabel);
    }

    function buildJobStepper() {
        const stepsBuilder = [];
        let activeStepIndex = -1;
        const { summary } = job;

        let isJobFailed;

        const MARK_JOB_ACTIVE_WHEN = [
            JobStatus.ACTIVE,
            JobStatus.FAILED,
            JobStatus.RUNNING,
        ];

        const MARK_JOB_FAIL_WHEN = [JobStatus.FAILED];

        summary?.forEach((pair, index) => {
            const step = pair[0];
            const status = pair[1].toLowerCase();

            // set status
            const label = `${step}: ${capitalize(status)}`;
            const stepToAdd = {
                id: step,
                label: capitalizeStepLabel(label),
                isShowingSpinning: false,
            };

            if (MARK_JOB_ACTIVE_WHEN.includes(status)) {
                activeStepIndex = index;
            }
            if (MARK_JOB_FAIL_WHEN.includes(status)) {
                isJobFailed = true;
                stepToAdd.isFailed = true;
            }

            // Setup spinning effect
            if (index === 0 && status === "pending") {
                // When all steps are pending:
                // 1. Make fitst step as active step & show spinning effect
                activeStepIndex = 0;
                stepToAdd.isShowingSpinning = true;
            } else if (
                activeStepIndex === index &&
                JobStepsFinalStatus[step] !== status
            ) {
                // Show spinning effect if it's not final status
                stepToAdd.isShowingSpinning = true;
            }
            stepToAdd.status = status;
            stepsBuilder.push(stepToAdd);
        });

        // nothing failed or active
        if (activeStepIndex === -1) {
            // find last finished element
            const summaryCopy = JSON.parse(JSON.stringify(summary));
            const lastFinishedStep = summaryCopy
                .reverse()
                .findIndex((s) => s[1] === "finished");
            if (lastFinishedStep !== -1) {
                const lastFinishedJobIndex = summary.length - lastFinishedStep;
                activeStepIndex = lastFinishedJobIndex;
            }
            // When activeStep's status is pending, we show the spinning
            if (stepsBuilder?.[activeStepIndex]?.status === "pending") {
                stepsBuilder[activeStepIndex].isShowingSpinning = true;
                stepsBuilder[activeStepIndex].active = true;
            }
            if (stepsBuilder?.[activeStepIndex]?.status === "inactive") {
                stepsBuilder[activeStepIndex].isShowingSpinning = false;
                stepsBuilder[activeStepIndex].active = false;
            }
        }

        setActiveStep(activeStepIndex);
        setSteps(stepsBuilder);
    }

    useEffect(() => {
        buildJobStepper();
        setScroller();
    }, [job]);

    return (
        <>
            <div className="sticky-container-top"> </div>
            <div
                id={id}
                data-testid={`job-stepper-${id}`}
                className="sticky-container"
            >
                {activeStep !== undefined && steps && (
                    <Stepper steps={steps} activeStep={activeStep} />
                )}
            </div>
        </>
    );
}

JobStepper.propTypes = {
    job: PropTypes.shape({
        summary: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
        resources: PropTypes.shape({
            webservice: PropTypes.string,
        }),
        status: PropTypes.string,
    }).isRequired,
    id: PropTypes.string,
};

JobStepper.defaultProps = {
    id: uuid.v4(),
};

export default JobStepper;
