import React, { useEffect, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Card, CircularProgress, LinearProgress } from "@mui/material";

import HelperTooltip from "../../../components/HelperTooltip";
import { AppContext } from "../../../context/AppContext";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import SettingsGridLayout from "./SettingsGridLayout";
import { AccountContext } from "../../user/Account";
import { UserType } from "../../../models/enum.ts";
import { formatDate, getBillingDue } from "../../../utils/utils.ts";

import StyledCreditUsage from "../../../styles/components/Billing/CreditUsage.Styled";
import UpdatePaymentMethodNotice from "../../../components/Pricings/UpdatePaymentMethodNotice";

function BillingGrid() {
    const { user, updateSnackbarStatus, creditInfo } = useContext(AppContext);
    const { userType } = useContext(AccountContext);

    const [billingHistory, setBillingHistory] = useState([]);
    const [historyLoading, setHistoryLoading] = useState(true);
    const [shouldPaginate, setShouldPaginate] = useState(false);

    const creditsStartPeriod =
        userType === UserType.COMMUNITY.value
            ? "on the first of each month"
            : "at the beginning of each billing cycle";
    const creditUsageHelpText = `You receive ${creditInfo.total_available_credits.toFixed(
        2
    )} credits ${creditsStartPeriod}. Credit use is calculated using the runtime of your apps and their hardware (CPU, RAM, GPU). If you exceed your allotted credits, you will be charged per-usage. Alternatively, you can purchase additional credits ahead of time.`;

    const exportHelpText =
        userType === UserType.COMMUNITY.value
            ? "Export usage per app for the current month"
            : "Export usage per app for the current billing period";

    useEffect(() => {
        const fetchBillingHistory = async () => {
            try {
                setHistoryLoading(true);
                const response = await ploomberAPI.getUserBillingHistory(
                    user.id
                );
                setBillingHistory(response);
                setShouldPaginate(
                    Array.isArray(response) && response.length > 5
                );
            } catch (error) {
                updateSnackbarStatus({
                    message: "Failed to fetch user billing history",
                    severity: "error",
                });
            } finally {
                setHistoryLoading(false);
            }
        };
        fetchBillingHistory();
    }, [user]);

    const convertToCSV = (objArray) => {
        const headers = ["Project", "CPU Usage", "RAM Usage", "GPU Usage"];
        const csvRows = [];
        csvRows.push(headers.join(","));

        Object.entries(objArray).forEach(([appName, specs]) => {
            const cpu = specs.cpu_usage;
            const ram = specs.ram_usage;
            const gpu = specs.gpu_usage;
            const csvRow = `${appName},${cpu},${ram},${gpu}`;
            csvRows.push(csvRow);
        });
        return csvRows.join("\n");
    };

    const handleBillingInfoExport = () => {
        const csv = convertToCSV(creditInfo.usage_by_app);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const exportDate = new Date().toISOString().split("T")[0];
        a.download = `ploomber_billing_information_${exportDate}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const billingHistoryColumnDefs = [
        {
            headerName: "Transaction ID",
            field: "invoice_id",
            sortable: false,
            flex: 2,
        },
        {
            headerName: "Date",
            width: 130,
            field: "invoice_date",
            valueFormatter: (params) => {
                const date = new Date(params.value * 1000);
                return date.toISOString().slice(0, 10);
            },
            filter: "agDateColumnFilter",
            sort: "desc",
        },
        {
            headerName: "Amount",
            field: "invoice_amount",
            type: "numericColumn",
            valueFormatter: (params) => `$${params.value.toFixed(2)}`,
        },
        { headerName: "Status", field: "status" },
    ];

    return (
        <SettingsGridLayout
            id="billingGrid"
            loading={historyLoading}
            title="Billing"
            actions={
                <HelperTooltip text={exportHelpText} placement="top">
                    <Button
                        data-testid="exportUsageButton"
                        variant="contained"
                        color="primary"
                        onClick={handleBillingInfoExport}
                        disabled
                    >
                        Export
                    </Button>
                </HelperTooltip>
            }
        >
            <h4>Credit usage</h4>

            {creditInfo.loading && <CircularProgress size={20} />}

            {!creditInfo.loading &&
                creditInfo.total_available_credits !== 0 && (
                    <StyledCreditUsage item xs={12}>
                        <div className="BillingGrid">
                            <div
                                className="BillingDue"
                                data-testid="billingDue"
                            >
                                Next billing due:{" "}
                                {getBillingDue(
                                    creditInfo.billing_period_report.billing_end
                                )}
                            </div>
                        </div>

                        <Card className="CreditsCard">
                            <div className="CreditsBarContainer">
                                <div className="CreditsBar">
                                    Credits applied
                                    <HelperTooltip
                                        text={creditUsageHelpText}
                                        placement="right-start"
                                    />
                                </div>
                                <div data-testid="creditUsage">
                                    {/* {creditInfo.billing_period_report.total_credits_used}/ */}
                                    0/
                                    {creditInfo.total_available_credits.toFixed(
                                        2
                                    )}
                                </div>
                            </div>

                            <LinearProgress
                                variant="determinate"
                                value={
                                    (creditInfo.used_credits /
                                        creditInfo.total_available_credits) *
                                    100
                                }
                            />
                        </Card>
                    </StyledCreditUsage>
                )}

            {!creditInfo.loading &&
                creditInfo.total_available_credits === 0 && (
                    <div style={{ paddingBottom: "1rem" }}>
                        No data available. Join our{" "}
                        <a
                            href="https://ploomber-io.slack.com/join/shared_invite/zt-rzu2e9f6-kaWgCfsLY~xeJ9vG9EIuBw?utm_source=cloud_app"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                textDecoration: "underline",
                                marginRight: "0.3rem",
                            }}
                        >
                            Slack
                        </a>
                        for support.
                    </div>
                )}

            <h4>Billing history</h4>

            <AgGridReact
                autoSizePadding={0}
                domLayout="autoHeight"
                columnDefs={billingHistoryColumnDefs}
                rowData={Array.isArray(billingHistory) ? billingHistory : []}
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                    flex: 1,
                }}
                enableCellTextSelection
                pagination={shouldPaginate}
                paginationPageSize={5}
                suppressPaginationPanel={!shouldPaginate}
            />

            {userType !== UserType.COMMUNITY.value && (
                <UpdatePaymentMethodNotice />
            )}
        </SettingsGridLayout>
    );
}

export default BillingGrid;
