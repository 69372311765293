import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledChip = styled(Chip)(({ theme }) => ({
    "&.MuiChip-colorInfo": {
        backgroundColor: "#E3D026",
        color: "#000",
    },
    "& .MuiCircularProgress-root": {
        width: "0.750rem !important",
        height: "0.750rem !important",
    },
}));

export default StyledChip;
