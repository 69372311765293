/* eslint @typescript-eslint/no-shadow: "off" */
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import BugReportIcon from "@mui/icons-material/BugReport";
import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { JobStatus, UserType } from "../../models/enum.ts";
import { AppContext } from "../../context/AppContext";
import { ActionButton, RefreshButton } from "../../components/UI";
import ploomberAPI from "../../services/ploomberAPI.ts";
import telemetry from "../../services/telemetry.ts";
import LogsTabs from "./components/LogsTabs";
import HelperTooltip from "../../components/HelperTooltip";
import {
    extractLatestTimestamp,
    parseErrorMessage,
} from "../../utils/utils.ts";
import JobStatusViewer from "./JobStatusViewer";
import {
    withApplicationProvider,
    ApplicationContext,
} from "./ApplicationProvider";
import { AccountContext } from "../user/Account";
import { createDownload } from "../../utils/uiUtils.ts";
import DebugActionGroup from "../../styles/components/DebugActionGroup.Styled";

const LogsIds = {
    DOCKER_LOGS: "docker-logs",
    WEBSERVICE_LOGS: "deployment-logs",
};

const handleUpgradeClick = () => {
    document.querySelector("[data-testid='upgradeButton']").click();
};

function getDockerBuildErrorMessage(jobStatus) {
    let dockerErrorMessage;
    if (jobStatus === JobStatus.DOCKER_FAILED_INSUFFICIENT_MEMORY) {
        dockerErrorMessage = (
            <div>
                Looks like your build needed too much memory!
                <div>
                    Click{" "}
                    <a
                        rel="noopener noreferrer"
                        data-testid="installing-pytorch-link"
                        href="https://docs.cloud.ploomber.io/en/latest/faq/faq.html#installing-pytorch"
                        target="_blank"
                    >
                        here
                    </a>{" "}
                    for instructions on how to resolve.
                </div>
            </div>
        );
    } else if (jobStatus === JobStatus.DOCKER_FAILED_TIMEOUT) {
        const { userType } = useContext(AccountContext);
        if ([UserType.COMMUNITY.value, UserType.PRO.value].includes(userType)) {
            dockerErrorMessage = (
                <div>
                    Looks like your build has timed out!
                    <br />
                    <div
                        style={{ display: "inline-flex", alignItems: "center" }}
                    >
                        <Box
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginRight: "6px",
                            }}
                            data-testid="upgrade-button-timeout"
                            onClick={handleUpgradeClick}
                        >
                            Upgrade
                        </Box>{" "}
                        your account to get more build time!
                    </div>
                </div>
            );
        } else if (userType === UserType.TEAMS.value) {
            dockerErrorMessage = (
                <div>
                    Looks like your build has timed out!
                    <br />
                    If you need more build time send us an email:
                    contact@ploomber.io
                </div>
            );
        }
    }

    return dockerErrorMessage;
}

function getLogsIdToShow(job) {
    if ("summary" in job) {
        const { summary } = job;

        const buildDockerStep = summary[0];
        const isFinished = buildDockerStep[1] === "finished";

        let logIdToShow;
        if (isFinished) {
            logIdToShow = LogsIds.WEBSERVICE_LOGS;
        } else {
            logIdToShow = LogsIds.DOCKER_LOGS;
        }

        return logIdToShow;
    }
    return LogsIds.DOCKER_LOGS;
}

const marginTop = 5;
const noLogLimit = 3;

function ApplicationStatus({ testData }) {
    const { jobInfo, jobId, paramProjectId } = useContext(ApplicationContext);
    const { canUserAccessComponent } = useContext(AccountContext);
    const isHistoricalLogBtnEnabled =
        canUserAccessComponent("getHistoricalLogs");
    const [jobStatus, setJobStatus] = useState();
    const [job, setJob] = useState();
    const [project, setProject] = useState();
    const [dockerLogs, setDockerLogs] = useState();
    const [webserviceLogs, setWebserviceLogs] = useState();
    const [fetchJobInternalTimeout, setFetchJobInternalTimeout] =
        useState(5000);
    const [isUrlUp, setIsUrlUp] = useState(false);
    const [logsIdToShow, setLogsIdToShow] = useState();
    const [lastLogTimestamp, setLastLogTimestamp] = useState();
    const [noNewLogs, setNoNewLogs] = useState(false);
    const { navigate, updateSnackbarStatus } = useContext(AppContext);

    const [consecutiveNoLogs, setConsecutiveNoLogs] = useState(0);
    const consecutiveNoLogsRef = useRef(consecutiveNoLogs);
    consecutiveNoLogsRef.current = consecutiveNoLogs;

    const [projectId, setProjectId] = useState(paramProjectId);

    const debugButtonEnable = useMemo(
        () =>
            jobStatus?.includes("failed") ||
            jobStatus?.includes("stopped") ||
            jobStatus?.includes("running"),
        [jobStatus]
    );

    const debugButtonPopUp = useMemo(
        () => jobStatus?.includes("failed"),
        [jobStatus]
    );

    const onApplicationDeleted = () => {
        navigate(`/applications`);
    };

    useEffect(() => {
        if (job) {
            setLogsIdToShow(getLogsIdToShow(job));
        }
    }, [jobStatus]);

    useEffect(() => {
        if (job) {
            const allowedProjectNames = [job.projectId, job.projectName];
            const isValidProjectName =
                allowedProjectNames.includes(paramProjectId);
            if (isValidProjectName) {
                setProjectId(job.projectId);
            } else {
                updateSnackbarStatus({
                    message: "Application not found",
                    severity: "error",
                });
                navigate(`/applications`);
            }
        }
    }, [job]);

    // This is a walkaround to pass test data
    // instead of mocking useEffect and async
    // interval which may be a complicated task

    useEffect(() => {
        if (testData) {
            const { logs, job } = testData;
            setDockerLogs(logs["build-docker"]);
            setWebserviceLogs(logs.webservice);
            setIsUrlUp(job?.resources?.is_url_up || false);
            setTimeout(() => {
                setJob(job);
            }, 500);
        }
    }, [testData]);

    function navIfOutdatedJob() {
        // Compare the current jobId to the most recent jobId for this project
        ploomberAPI
            .getUserProject(projectId)
            .then((projectInfo) => {
                if (!projectInfo.jobs) {
                    return;
                }
                const mostRecentJobId = projectInfo.jobs[0].id;
                // Found a more recent jobId
                if (mostRecentJobId !== jobId) {
                    // Snackbar
                    updateSnackbarStatus({
                        message:
                            "Project has been redeployed. Redirecting to the new status page.",
                        severity: "info",
                    });
                    // Delay 3 seconds, then redirect
                    setTimeout(() => {
                        // Switch to most recent job status page
                        navigate(
                            `/applications/${projectId}/${mostRecentJobId}`
                        );
                        // Refresh to update job status viewer and logs
                        navigate(0);
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log("FAILED TO FETCH MOST RECENT JOB ", err);
            });
    }

    function fetchJob() {
        return new Promise((resolve) => {
            ploomberAPI
                .getJob(jobId)
                .then((inputJob) => {
                    setJob(inputJob);
                    setJobStatus(inputJob.status);
                    if (inputJob.status === "stopped") {
                        const stoppedJobMessage =
                            "\nApplication is currently stopped. No new logs will show up.";
                        setWebserviceLogs((prevLogs) => {
                            if (!prevLogs) {
                                // If prevLogs is null or undefined, only return the message
                                return stoppedJobMessage;
                            }

                            // If prevLogs ends with the stoppedJobMessage, return it as is, otherwise append the message
                            return prevLogs.endsWith(stoppedJobMessage)
                                ? prevLogs
                                : prevLogs + stoppedJobMessage;
                        });
                    } else if (inputJob.status === "finished") {
                        // When project is redeployed, old jobs are set to finished so we check for a new one
                        navIfOutdatedJob();
                    }
                    resolve(inputJob);
                })
                .catch((err) => {
                    // Handle application not found
                    updateSnackbarStatus({
                        message: "Application not found",
                        severity: "error",
                    });
                    setTimeout(() => navigate("/applications"), 100);
                })
                .finally(() => {
                    resolve();
                });
        });
    }

    function fetchLogs(lastTimestamp) {
        // lastTimestamp is used to track timestamp of last log entry
        ploomberAPI
            .getJobLogs(jobId)
            .then((logs) => {
                const newDockerLogs = logs["build-docker"];
                const newWebserviceLogs = logs.webservice;
                const hasNewDockerLogs = newDockerLogs !== dockerLogs;
                const hasNewWebServiceLogs =
                    newWebserviceLogs !== webserviceLogs;
                let latestDockerTimestamp = 0;
                let latestWebServiceTimestamp = 0;

                if (hasNewDockerLogs || hasNewWebServiceLogs) {
                    if (hasNewDockerLogs) {
                        setDockerLogs(newDockerLogs);
                        latestDockerTimestamp =
                            extractLatestTimestamp(newDockerLogs);
                    }
                    if (hasNewWebServiceLogs) {
                        setWebserviceLogs(newWebserviceLogs);
                        latestWebServiceTimestamp =
                            extractLatestTimestamp(newWebserviceLogs);
                    }

                    setLastLogTimestamp(
                        Math.max(
                            latestDockerTimestamp,
                            latestWebServiceTimestamp
                        )
                    );
                    setNoNewLogs(false);
                    setConsecutiveNoLogs(0); // Reset the counter for no new logs
                } else {
                    // No new logs found
                    setNoNewLogs(true);
                    setConsecutiveNoLogs((count) => count + 1);
                }
            })
            .catch((err) => {
                console.log("FAILED TO FETCH DEPLOYMENT LOGS ", err);
            });
    }

    function fetchData() {
        fetchJob();
        fetchLogs(lastLogTimestamp);
    }

    const handleHistoricalLogsClick = async () => {
        try {
            const logs = await ploomberAPI.getProjectLogs(projectId);
            const jsonString = JSON.stringify(logs, null, 2);
            const blob = new Blob([jsonString], { type: "application/json" });
            createDownload(`history_logs_${projectId}`, blob);
        } catch (e) {
            updateSnackbarStatus({
                message: parseErrorMessage(e),
                severity: "error",
            });
            console.error(e);
        }
    };

    const handleDebugThisPipelineClick = async () => {
        const tokenList = await ploomberAPI.listAPIKeys();
        let params = `project=${projectId}`;
        if (tokenList.length > 0) {
            params += `&token=${tokenList[0].api_key}`;
        }
        const newUrl = `https://debugger.ploomberapp.io/?${params}`;
        window.open(newUrl, "_blank");
    };

    const isFetchingJobDataRef = useRef(false);

    function fetchJobAsync() {
        if (isFetchingJobDataRef.current) {
            // Already fetching, skipping this call.
            return Promise.resolve();
        }

        isFetchingJobDataRef.current = true;

        return new Promise((resolve) => {
            fetchJob()
                .then((job) => {
                    const isServingTrafficActive = job.summary.some(
                        ([step, status]) =>
                            step === "serving-traffic" && status === "active"
                    );
                    setIsUrlUp(isServingTrafficActive);
                    setJob(job);
                    isFetchingJobDataRef.current = false;
                })
                .catch(() => {})
                .finally(() => {
                    resolve();
                });
        });
    }

    useEffect(() => {
        let fetchJobInterval;
        let fetchLogsInterval;

        // Initial fetch
        const initialFetch = async () => {
            await fetchJobAsync();
            fetchLogs(lastLogTimestamp);

            // Set up intervals
            fetchJobInterval = setInterval(
                fetchJobAsync,
                fetchJobInternalTimeout
            );

            fetchLogsInterval = setInterval(() => {
                // When the job is running, only fetch logs if we have not hit the no new logs limit
                // Always fetch logs when the job is not stopped or failed
                if (
                    (jobStatus === "running" &&
                        consecutiveNoLogsRef.current < noLogLimit) ||
                    (jobStatus !== "stopped" && !jobStatus?.includes("failed"))
                ) {
                    fetchLogs(lastLogTimestamp);
                } else {
                    // If no new logs for more than 3 times consecutively, stop the intervals
                    clearInterval(fetchLogsInterval);
                    console.log(
                        "Stopped polling due to no new logs received consecutively more than 3 times."
                    );
                }
            }, fetchJobInternalTimeout);
        };

        // Initial fetch
        initialFetch();

        telemetry.log(telemetry.Events.PageView);

        return function cleanup() {
            clearInterval(fetchJobInterval);
            clearInterval(fetchLogsInterval);
        };
    }, [lastLogTimestamp]);

    useEffect(() => {
        // Increase fetch job timeout since we don't want
        // to keep pinging the url. Otherwise we check it for every 5 seconds.
        if (isUrlUp) {
            setFetchJobInternalTimeout(10000);
        } else {
            setFetchJobInternalTimeout(5000);
        }
    }, [isUrlUp]);

    if (!job) {
        return "";
    }

    const logsPlaceholder = "Waiting for logs...";

    const dockerErrorMessage = getDockerBuildErrorMessage(job.status);

    return (
        <Box
            data-testid="application-status"
            style={{ backgroundColor: "#fdfdfd" }}
        >
            <Box
                style={{
                    display: "inline-flex",
                    width: "100%",
                }}
            >
                <Box style={{ width: "100%" }}>
                    <JobStatusViewer
                        status={jobStatus}
                        projectId={projectId}
                        project={project}
                        jobInfo={jobInfo}
                        job={job}
                        jobId={jobId}
                        isUrlUp={isUrlUp}
                        onApplicationDeleted={onApplicationDeleted}
                    />
                </Box>
            </Box>

            <Box mt={marginTop} mb={marginTop}>
                <Box sx={{ width: "100%", textAlign: "right" }}>
                    <HelperTooltip text="Fetch data">
                        <RefreshButton
                            style={{ margin: "auto 20px" }}
                            onClick={() => {
                                telemetry.log(
                                    `${telemetry.Pages.ApplicationStatus}-RefreshClick`,
                                    {
                                        metadata: {
                                            job: JSON.stringify(job),
                                            projectId,
                                        },
                                    }
                                );
                                fetchData();
                            }}
                        />
                    </HelperTooltip>
                </Box>
                <LogsTabs
                    logsIdToShow={logsIdToShow}
                    logs={[
                        {
                            title: "Docker logs",
                            errorMessage: dockerErrorMessage,
                            logs: dockerLogs,
                            id: LogsIds.DOCKER_LOGS,
                            placeholder: logsPlaceholder,
                        },
                        {
                            title: "Webservice logs",
                            logs: webserviceLogs,
                            placeholder: logsPlaceholder,
                            id: LogsIds.WEBSERVICE_LOGS,
                        },
                    ]}
                />
                <DebugActionGroup>
                    <HelperTooltip text="Debug this application">
                        <ActionButton
                            data-testid="go-to-debugger-btn"
                            disabled={!debugButtonEnable}
                            startIcon={<BugReportIcon />}
                            onClick={handleDebugThisPipelineClick}
                            variant={
                                debugButtonPopUp ? "contained" : "outlined"
                            }
                            color={debugButtonPopUp ? "error" : "primary"}
                        >
                            Debug this Deployment
                        </ActionButton>
                    </HelperTooltip>
                    <HelperTooltip text="Download the full log history of this application">
                        <ActionButton
                            data-testid="download-historical-logs-btn"
                            disabled={!isHistoricalLogBtnEnabled}
                            startIcon={<FileDownloadOutlinedIcon />}
                            onClick={handleHistoricalLogsClick}
                            variant="outlined"
                            color="primary"
                        >
                            Download Historical logs
                        </ActionButton>
                    </HelperTooltip>
                </DebugActionGroup>
            </Box>
        </Box>
    );
}

ApplicationStatus.propTypes = {
    testData: PropTypes.shape({
        job: PropTypes.shape({
            projectId: PropTypes.string.isRequired,
            summary: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
            status: PropTypes.string.isRequired,
            resources: PropTypes.shape({
                webservice: PropTypes.string,
                is_url_up: PropTypes.bool,
            }),
            labels: PropTypes.arrayOf(PropTypes.string),
        }),
        logs: PropTypes.shape({
            "build-docker": PropTypes.string,
            webservice: PropTypes.string,
        }),
    }),
};

ApplicationStatus.defaultProps = {
    testData: undefined,
};

export default withApplicationProvider(ApplicationStatus);
