import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { ListItemIcon } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import ploomberAPI from "../services/ploomberAPI.ts";
import { AccountContext } from "../features/user/Account";
import { UserType } from "../models/enum.ts";
import { AppContext } from "../context/AppContext";
import telemetry from "../services/telemetry.ts";
import HelperTooltip from "./HelperTooltip";
import { PricingsDialog } from "./Pricings";
import { shouldValidateAction, capitalize } from "../utils/utils.ts";
import pricing from "./Pricings/Pricing.json";
import ConfirmSubscriptionCancellationDialog from "./Pricings/ConfirmSubscriptionCancellationDialog";

export async function handleStripeSubmit(
    updateSnackbarStatus,
    upgradeType,
    newTab = false
) {
    let paymentUrl;
    try {
        paymentUrl = await ploomberAPI.createPaymentLink(upgradeType);
    } catch (error) {
        updateSnackbarStatus({
            message: `Error creating payment link: ${error}`,
            severity: "error",
        });
        return;
    }

    if (newTab) {
        window.open(paymentUrl.payment_url, "_blank").focus();
    } else {
        window.location.href = paymentUrl.payment_url;
    }
}

function StripePaymentButton({ showText }) {
    const { userType, setUserType, userHadFreeTrial, subscription } =
        useContext(AccountContext);
    const { navigate, updateSnackbarStatus } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [openPricingDialog, setOpenPricingDialog] = useState(false);
    const [openDowngradeDialog, setOpenDowngradeDialog] = useState(false);
    const trial = localStorage.getItem("trial");

    const verifyUpgradeTimeout = 5 * 60 * 1000; // 5 minutes

    const upgradeButtonText = userHadFreeTrial
        ? "Upgrade"
        : "Start your 10-day free trial";

    const communitySubscriptionStatus = () => {
        if (subscription && subscription.cancel_at_period_end) {
            return {
                title: "Subscription Canceled",
                subText:
                    "You will be automatically downgraded at the end of the billing period.",
                disabled: true,
                onClick: () => {},
            };
        }
        if (shouldValidateAction(userType, UserType.COMMUNITY.value)) {
            return {
                title: "Downgrade",
                disabled: false,
                onClick: () => setOpenDowngradeDialog(true),
            };
        }
        // Won't get here
        return {
            title: "",
            disabled: false,
            onClick: () => setOpenDowngradeDialog(true),
        };
    };

    const subscriptionInfo = communitySubscriptionStatus();

    const pricingPlans = [
        {
            id: UserType.COMMUNITY.value,
            title: UserType.COMMUNITY.label,
            priceHTML: <div className="Price">Free</div>,
            features: pricing.sections.community,
            primaryAction: {
                title: subscriptionInfo.title,
                onClick: subscriptionInfo.onClick,
            },
            disabled: subscriptionInfo.disabled,
            toolTipText: subscriptionInfo.subText,
            preSelected: false,
        },
        {
            id: UserType.PRO.value,
            title: UserType.PRO.label,
            priceHTML: (
                <div>
                    <div className="Small">Starting at</div>

                    <div className="Price">$20</div>

                    <div className="Small">per month</div>
                </div>
            ),
            features: pricing.sections.professional,
            primaryAction: {
                title: shouldValidateAction(userType, UserType.PRO.value)
                    ? "Downgrade"
                    : upgradeButtonText,
                onClick: (id) => {
                    handleStripeSubmit(updateSnackbarStatus, id);
                },
            },
            preSelected: trial === UserType.PRO.value,
        },
        {
            id: UserType.TEAMS.value,
            title: UserType.TEAMS.label,
            priceHTML: (
                <div>
                    <div className="Small">Starting at</div>

                    <div className="Price">$200</div>

                    <div className="Small">per month</div>
                </div>
            ),
            features: pricing.sections.teams,
            primaryAction: {
                title: shouldValidateAction(userType, UserType.TEAMS.value)
                    ? "Downgrade"
                    : upgradeButtonText,
                onClick: (id) => {
                    handleStripeSubmit(updateSnackbarStatus, id);
                },
            },
            preSelected: trial === UserType.TEAMS.value,
        },
        {
            id: UserType.ENTERPRISE.value,
            title: UserType.ENTERPRISE.label,
            priceHTML: (
                <div className="Price CustomPricing">
                    <div>Custom pricing</div>
                </div>
            ),
            features: pricing.sections.enterprise,
            primaryAction: {
                title: "Let's talk",
                onClick: () => {
                    window.open(
                        "https://calendly.com/d/d2s-xd9-4r9/ploomber-founders",
                        "_blank"
                    );
                },
            },
            preSelected: trial === UserType.ENTERPRISE.value,
        },
    ];

    useEffect(() => {
        setIsLoading(true);

        if (trial) {
            setOpenPricingDialog(true);
        }

        let intervalId;
        const updateUserSession = async () => {
            try {
                const searchParams = new URLSearchParams(
                    window.location.search
                );
                const sessionId = searchParams.get("session_id");

                // No checkout session ID found so checkout was not completed, just update user info once
                if (!sessionId) {
                    setIsLoading(false);
                    return;
                }

                // Check if upgrade was detected
                const verificationResult = await ploomberAPI.verifyUpgrade(
                    sessionId
                );

                if (verificationResult.upgrade_detected) {
                    // Upgrade detected, update user info
                    const userSession = await ploomberAPI.getUserInfo();
                    const returnedUserType = userSession?._model?.type;
                    localStorage.setItem(
                        "ploomber_user_session",
                        JSON.stringify(userSession._model)
                    );
                    setUserType(returnedUserType);

                    updateSnackbarStatus({
                        message: `Successful Update to ${capitalize(
                            returnedUserType
                        )} User`,
                    });
                    telemetry.log(`Payment Verified`, {
                        includeUserDetails: true,
                        metadata: { type: returnedUserType },
                    });

                    clearInterval(intervalId);
                    navigate("/applications");
                    setIsLoading(false);
                }
                // Continue polling if upgrade not detected
            } catch (error) {
                updateSnackbarStatus({
                    message: `Error verifying upgrade: ${error}`,
                    severity: "error",
                });
                clearInterval(intervalId);
                setIsLoading(false);
                navigate("/applications");
            }
        };

        // Start polling if we have a session ID - meaning the user completed checkout
        const sessionId = new URLSearchParams(window.location.search).get(
            "session_id"
        );
        if (sessionId) {
            intervalId = setInterval(updateUserSession, 1000);
            updateSnackbarStatus({
                message: `Upgrade verification in progress... Please wait.`,
                severity: "info",
                duration: verifyUpgradeTimeout,
            });

            // Stop polling after verifyUpgradeTimeout seconds
            setTimeout(() => {
                if (intervalId) {
                    clearInterval(intervalId);
                    updateSnackbarStatus({
                        message: `Upgrade verification timed out. Please contact support if the issue persists.`,
                        severity: "error",
                        duration: 20000,
                    });
                    setIsLoading(false);
                    navigate("/applications");
                }
            }, verifyUpgradeTimeout);
        } else {
            // Just update user info once
            updateUserSession();
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []); // Empty dependency array ensures this runs once

    if (isLoading) {
        return <div />;
    }

    return (
        <div>
            <ConfirmSubscriptionCancellationDialog
                open={openDowngradeDialog}
                onClose={() => {
                    setOpenDowngradeDialog(false);
                }}
                onSubmitCallback={() => {
                    setOpenPricingDialog(false);
                }}
            />
            <HelperTooltip
                text={showText ? "" : "Upgrade"}
                placement="right-end"
            >
                <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: showText ? "initial" : "center",
                            px: 2.5,
                        }}
                        onClick={() => setOpenPricingDialog(true)}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: showText ? 2 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <UpgradeIcon />
                        </ListItemIcon>
                        <ListItemText
                            data-testid="upgradeButton"
                            primary="Upgrade"
                            sx={{ opacity: showText ? 1 : 0 }}
                        />
                    </ListItemButton>
                </ListItem>
            </HelperTooltip>

            <PricingsDialog
                pricingPlans={pricingPlans}
                open={openPricingDialog}
                onClose={() => {
                    // remove trial from localStorage so the stripe dialog is not shown
                    // again
                    localStorage.removeItem("trial");
                    setOpenPricingDialog(false);
                }}
            />
        </div>
    );
}

StripePaymentButton.propTypes = {
    showText: PropTypes.bool,
};

StripePaymentButton.defaultProps = { showText: true };

export default StripePaymentButton;
