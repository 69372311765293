import JSZip from "jszip";
import { Framework } from "../models/enum.ts";
import ploomberAPI from "./ploomberAPI.ts";

/**
 * Parses a GitHub URL and extracts repository information.
 *
 * @param {string} githubUrl - The GitHub URL to parse.
 * @returns {Object} An object containing parsed information:
 *   - owner: The repository owner's username.
 *   - repo: The repository name.
 *   - ref: The branch or commit reference (defaults to 'main').
 *   - path: The path within the repository (empty string if not specified).
 * @throws {Error} If the provided URL is not a valid GitHub template link.
 */
function parseGitHubUrl(githubUrl) {
    const regex =
        // eslint-disable-next-line
        /https:\/\/github\.com\/([^\/]+)\/([^\/]+)(\/tree\/[^\/]+\/)?(.*)/;
    const match = githubUrl.match(regex);
    if (!match) {
        throw new Error("Invalid link to a GitHub template.");
    }
    return {
        owner: match[1],
        repo: match[2],
        ref: match[3] ? match[3].split("/")[2] : "main",
        path: match[4] || "",
    };
}

/**
 * Strips the root path from a zip file and optionally filters for a subdirectory.
 *
 * @param {Blob} rawBlob - The original blob from Github containing the zip file.
 * @param {string} repoName - The name of the repository.
 * @param {string} [subdirPath] - Optional subdirectory path to filter the zip contents.
 * @returns {Promise<Object>} An object containing the processed zip and file.
 * @throws {Error} If no files are found for the template URL or if there's an error parsing the template.
 */
async function stripRootPath(rawBlob, repoName, subdirPath) {
    const rawFiles = new File([rawBlob], `${repoName}.zip`, {
        type: "application/zip",
    });
    const rawZip = await JSZip.loadAsync(rawFiles);
    try {
        // Only keep file in subdirectory
        if (subdirPath) {
            const rootDir = Object.keys(rawZip.files).filter((filename) =>
                filename.includes(subdirPath)
            );
            if (rootDir.length === 0) {
                throw new Error("No files found for the template URL.");
            }

            // Create a new zip file with only the root files
            const zip = new JSZip();
            rootDir.forEach((filename) => {
                const fileContent = rawZip.file(filename);
                if (fileContent) {
                    zip.file(
                        filename.slice(
                            filename.indexOf(subdirPath) + subdirPath.length + 1
                        ),
                        fileContent.async("uint8array")
                    );
                }
            });

            // Create a new File object with the filtered zip content
            const blob = await zip.generateAsync({ type: "blob" });
            const file = new File([blob], `${repoName}.zip`, {
                type: "application/zip",
            });
            return { zip, file };
        }
        // Otherwise keep everything, but remove the root directory from all paths
        const rootDir = Object.keys(rawZip.files)[0].split("/")[0];
        if (rootDir.length === 0) {
            throw new Error("No files found for the template URL.");
        }

        // Create New zip with by removing the leading `root/`
        const zip = new JSZip();
        Object.keys(rawZip.files).forEach((filename) => {
            if (rawZip.file(filename) === null) return;
            if (
                filename === `${rootDir}/` ||
                !filename.startsWith(`${rootDir}/`)
            )
                return;
            const newFilename = filename.substring(rootDir.length + 1); // +1 for ending `/`
            zip.file(newFilename, rawZip.file(filename).async("uint8array"));
        });
        // Create a new File object with the filtered zip content
        const blob = await zip.generateAsync({ type: "blob" });
        const file = new File([blob], `${repoName}.zip`, {
            type: "application/zip",
        });
        return { zip, file };
    } catch (e) {
        console.error(e);
        throw new Error("Error while parsing the template");
    }
}

/**
 * Infers the project type based on the contents of a zip file.
 *
 * @param {JSZip} zip - The JSZip object containing the project files.
 * @returns {Promise<Framework>} A promise that resolves to the inferred project framework.
 */
async function inferProjectType(zip) {
    const fileList = Object.keys(zip.files);
    const frameworkChecks = [
        [
            Framework.DOCKER,
            () => fileList.some((file) => file.endsWith("Dockerfile")),
        ],
        [
            Framework.VOILA,
            () => fileList.some((file) => file.endsWith(".ipynb")),
        ],
        [
            Framework.STREAMLIT,
            async () => {
                const pyFiles = fileList.filter((file) => file.endsWith(".py"));
                const contents = await Promise.all(
                    pyFiles.map((file) => zip.file(file).async("string"))
                );
                return contents.some(
                    (content) =>
                        content.includes("import streamlit") ||
                        content.includes("from streamlit")
                );
            },
        ],
        [
            Framework.PANEL,
            async () => {
                const pyFiles = fileList.filter((file) => file.endsWith(".py"));
                const contents = await Promise.all(
                    pyFiles.map((file) => zip.file(file).async("string"))
                );
                return contents.some(
                    (content) =>
                        content.includes("import panel") ||
                        content.includes("from panel")
                );
            },
        ],
        [
            Framework.SOLARA,
            async () => {
                const pyFiles = fileList.filter((file) => file.endsWith(".py"));
                const contents = await Promise.all(
                    pyFiles.map((file) => zip.file(file).async("string"))
                );
                return contents.some(
                    (content) =>
                        content.includes("import solara") ||
                        content.includes("from solara")
                );
            },
        ],
        [
            Framework.DASH,
            async () => {
                const pyFiles = fileList.filter((file) => file.endsWith(".py"));
                const contents = await Promise.all(
                    pyFiles.map((file) => zip.file(file).async("string"))
                );
                return contents.some(
                    (content) =>
                        content.includes("import dash") ||
                        content.includes("from dash")
                );
            },
        ],
        [
            Framework.FLASK,
            async () => {
                const pyFiles = fileList.filter((file) => file.endsWith(".py"));
                const contents = await Promise.all(
                    pyFiles.map((file) => zip.file(file).async("string"))
                );
                return contents.some((content) =>
                    content.includes("from flask")
                );
            },
        ],
        [
            Framework.CHAINLIT,
            async () => {
                const pyFiles = fileList.filter((file) => file.endsWith(".py"));
                const contents = await Promise.all(
                    pyFiles.map((file) => zip.file(file).async("string"))
                );
                return contents.some(
                    (content) =>
                        content.includes("import chainlit") ||
                        content.includes("from chainlit")
                );
            },
        ],
        [
            Framework.SHINY_R,
            async () => {
                const rFiles = fileList.filter((file) => file.endsWith(".R"));
                return rFiles.length > 0;
            },
        ],
    ];

    const results = await Promise.all(
        frameworkChecks.map(async ([framework, check]) => {
            const result = await check();
            return result ? framework : null;
        })
    );

    return results.find((result) => result !== null) || Framework.VOILA;
}

/**
 * Determines the project framework based on the contents of a zip file and the ploomber-cloud.json configuration.
 *
 * @param {JSZip} zip - The JSZip object containing the project files.
 * @returns {Promise<Framework>} A promise that resolves to the determined project framework.
 */
async function getFrameworkFromZip(zip) {
    const ploomberConfigFile = Object.keys(zip.files).find((filename) =>
        filename.endsWith("ploomber-cloud.json")
    );
    let framework = await inferProjectType(zip);
    if (ploomberConfigFile) {
        const ploomberJsonContent = await zip
            .file(ploomberConfigFile)
            .async("text");
        const ploomberJson = JSON.parse(ploomberJsonContent);
        const { type: configFramework } = ploomberJson;
        if (
            configFramework &&
            Object.values(Framework).includes(configFramework)
        ) {
            framework = configFramework;
        }
    }
    return framework;
}

/**
 * Fetches and processes a GitHub template as a Zip from a given URL.
 *
 * @param {string} githubUrl - The GitHub URL of the template to fetch.
 * @returns {Promise<Object>} A promise that resolves to an object containing:
 *   - file: A File object representing the processed template.
 *   - zip: A JSZip object containing the processed template files.
 * @throws {Error} If no files are found for the template URL or if the provided URL is not a valid GitHub template link.
 */
async function getTemplate(githubUrl) {
    // Use GitHub API to get the download URL for the zip
    const { owner, repo, ref, path } = parseGitHubUrl(githubUrl);
    const blob = await ploomberAPI.getGithubTemplate(owner, repo, ref);

    // Filter the repo to keep only the subtree
    const { zip, file } = await stripRootPath(blob, repo, path);

    Object.keys(zip.files).forEach((filename) => {
        console.log(filename);
    });

    // Attach special value to `UploadBox` to detect the name
    file.path = path ? `${path.split("/").pop()}.zip` : `${repo}.zip`;
    return { file, zip };
}

export { getTemplate, getFrameworkFromZip };
